import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface updateTaskSignParam {
    cycleType?:any
    rewardNumber?:any
    appId?:any
    rewardType?:any
    dayList?:any
    name?:any
    id?:any
    status?:any
}

export interface removeTaskParam {
    taskId?:any
}

export interface loadTaskSignDayParam {
    taskId?:any
}

export interface saveTaskSignParam {
    cycleType?:any
    rewardNumber?:any
    appId?:any
    rewardType?:any
    dayList?:any
    name?:any
    status?:any
}

export interface loadPageParam {
    current?:any
    size?:any
    enable?:any
    kind?:any
    appId?:any
    keyword?:any
    type?:any
}

export interface changeStatusParam {
    taskId?:any
}
// -------接口列------

/**
 * [后台]删除任务
 * @notes 
 */
export function removeTask(param:removeTaskParam) {
    return get('/task/tasksSignIn/removeTask', param);
}

/**
 * [后台]编辑-更新签到任务
 * @notes 
 */
export function updateTaskSign(param:updateTaskSignParam) {
    return post('/task/tasksSignIn/updateTaskSign', param);
}

/**
 * [后台]加载签到任务数据
 * @notes 
 */
export function loadPage(param:loadPageParam) {
    return get('/task/tasksSignIn/loadPage', param);
}

/**
 * [后台]保存-新增签到任务
 * @notes 
 */
export function saveTaskSign(param:saveTaskSignParam) {
    return post('/task/tasksSignIn/saveTaskSign', param);
}

/**
 * [后台]改变状态
 * @notes 
 */
export function changeStatus(param:changeStatusParam) {
    return get('/task/tasksSignIn/changeStatus', param);
}

/**
 * [后台]加载签到日期配置数据
 * @notes 
 */
export function loadTaskSignDay(param:loadTaskSignDayParam) {
    return get('/task/tasksSignIn/loadTaskSignDay', param);
}

