
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'MultipleDays'
})
export default class MultipleDays extends Vue {
    @Prop({ required: true, default: () => [] }) readonly daysConfig!: Array<{
        signDay: any
        rewardType: any
        rewardNumber: any
        replenishNumber: any
    }>;

    @Prop({ required: true }) readonly cycleType!: any;

    get dataSetsInfo(){
        return this.daysConfig;
    }

    set dataSetsInfo(val: any){
      this.$emit('update:daysConfig', val)
    }

    @Watch("cycleType")
    changeCycleType(){
        const size = this.dataSetsInfo.length
        if(this.cycleType === 'WEEK'){
            if(size > 7){
                this.dataSetsInfo = this.dataSetsInfo.slice(0,7)
            }else{
                for (let index = 0; index < 7; index++) {
                    this.dataSetsInfo.push({
                        signDay: size + index + 1,
                        rewardType: null,
                        rewardNumber: null,
                        replenishNumber: null
                    })
                }
            }
        }else if(this.cycleType === 'MOTH'){
            if(size < 31){
                for (let index = 0; index < 31 - size; index++) {
                    this.dataSetsInfo.push({
                        signDay: size + index + 1,
                        rewardType: null,
                        rewardNumber: null,
                        replenishNumber: null
                    })
                }
            }
        }
    }
}
