
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { loadEnabled } from '@/api/system/appConfig';
import { loadTaskSignDay, saveTaskSign, updateTaskSign } from '@/api/task/tasksSignIn';
import MultipleDays from './MultipleDays.vue';

@Component({
    name: 'TaskEditOrAdd',
    components: {
      MultipleDays
    }
})
export default class TaskEditOrAdd extends Vue {
    @Prop({ required: true, default: false }) readonly dialogEditVisible!: boolean;
    @Prop({ required: true, default: () => {} }) readonly formData!: any;
    @Prop({ required: true, default: false }) readonly isEdit!:boolean;
    
    formParam: {
        id: any
        appId: any
        name: any
        cycleType: any
        rewardType: any
        rewardNumber: any
        completeCount: any
        dayList: Array<{
            rewardType: any
            rewardNumber: any
            replenishNumber: any
        }>
    } = {
        id: null,
        appId: null,
        name: null,
        cycleType: null,
        rewardType: null,
        rewardNumber: null,
        completeCount: null,
        dayList: []
    }

    appconfigs :any = []

    get dialogVisible(){
      return this.dialogEditVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogEditVisible', val)
    }

    get infoTitle(){
        if(this.isEdit){
            return "编辑任务-【" + this.formParam.name + "】";
        }else{
            return "添加任务";
        }
    }


    @Emit("getList")
    getList(){}

    handleUpdateTask(){
      if(this.isEdit){
        updateTaskSign(this.formParam).then(res => {
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
            this.dialogVisible = false
            this.getList();
        })
      }else{
        saveTaskSign(this.formParam).then(res=>{
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
            this.dialogVisible = false
            this.getList();
        })
      }
    }

    mounted(){
      this.getAppconfigs()
    }

    @Watch("formData")
    initParam(){
      if(this.isEdit){
        Object.assign(this.formParam,  this.formData)
        loadTaskSignDay({ taskId: this.formParam.id }).then(res => {
            this.formParam.dayList = res.data
        })
      }else{
        Object.assign(this.formParam, {
            id: null,
            appId: null,
            name: null,
            cycleType: null,
            rewardType: null,
            rewardNumber: null,
            completeCount: null,
            dayList: []
        })
      }
    }

    //加载应用信息
    getAppconfigs(){
      loadEnabled().then(res => {
          this.appconfigs = res.data
      })
    }
}
